import * as React from 'react';
import {
  VStack,
  Text,
  Heading,
  ListItem,
  Flex,
  UnorderedList,
  Box,
} from '@chakra-ui/react';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import { useMixpanel } from 'gatsby-plugin-mixpanel';

const EsignInformation: React.FC = () => {
  const mixpanel = useMixpanel();
  React.useEffect(() => {
    mixpanel.track('Homepage: Viewed Electronic Signature');
  }, [mixpanel]);

  return (
    <Layout>
      <SEO title="Electronic Signature" />
      <Flex direction="column" align="center" justify="center">
        <Heading size="lg" px={10} pt={10} textAlign="center">
          Canyou Electronic Signature
        </Heading>
        <VStack p={10} mx={5} spacing={4} maxWidth="900px">
          <Text>
            This information is general in nature. Please consult a lawyer for
            legal requirements when it comes to electronically executing
            (signing) documents.
          </Text>
          <Text alignSelf="baseline">
            Generally, there are three questions that must be explored before a
            document can be validly electronically signed.
          </Text>
          <Heading size="md" textAlign="center">
            1. What is the relevant law that requires the signature?
          </Heading>
          <Text>
            The person wanting to sign the document will need to look into what
            the relevant law that sets out the execution requirement for that
            document. For example:
            <UnorderedList>
              <ListItem>
                A will requires a signature of the testator and two witnesses.
              </ListItem>
              <ListItem>
                A residential tenancy agreement requires a signature in some
                states to be valid, but an inspection condition report does not
                in some states.
              </ListItem>
              <ListItem>
                Some contracts do not require a signature at all to be valid.
              </ListItem>
            </UnorderedList>
          </Text>
          <Text alignSelf="baseline">
            The onus to understand the signing requirements is the
            responsibility of the creator of the document.
          </Text>
          <Heading size="md" textAlign="center">
            2. Is an electronic signature permitted under that law?
          </Heading>
          <Text>
            Once the relevant law requiring the signature is determined, the
            creator of the document needs to be comfortable that that document
            can be electronically signed in order to be valid. For example,
            deeds in some states cannot be electronically signed. Land Titles
            documents in some states cannot be electronically signed either.
            Most contracts can be electronically signed.
          </Text>
          <Text alignSelf="baseline">
            Again, the onus of answering this question should be on the creator
            of the document.
          </Text>
          <Heading size="md" textAlign="center">
            3. If the answer to question 2 is “yes”, then satisfy the
            requirements in the relevant “ET Act”.
          </Heading>
          <Text>
            The “ET Act” is the “Electronic Transactions Act” that exists in
            every Australian State and Territory and at the Commonwealth Level
            (in total, 7 Acts). The ET Acts have provisions allowing signatures
            to be done electronically and fortunately, all 7 Acts are largely
            consistent in their requirements which are:
          </Text>
          <Text>
            <Box as="span" textDecoration="underline" fontWeight="bold">
              Identification
            </Box>{' '}
            - the parties to the document must be able to identify from the
            method of execution the person signing and confirm that the person
            signing intends to be bound by the information communicated - to
            this end, the signatory should include their name at the time of
            signature and there should be some note before signing which
            confirms that the person signing intends to be bound by the
            information communicated.
          </Text>
          <Text>
            <Box as="span" textDecoration="underline" fontWeight="bold">
              Reliability
            </Box>{' '}
            - the method used to sign must be reliable, which is objectively
            determined by considering all relevant circumstances and the purpose
            for which the signature is required - in other words, an electronic
            signature provider needs to reliably record the signature.
          </Text>
          <Text>
            <Box as="span" textDecoration="underline" fontWeight="bold">
              Consent
            </Box>{' '}
            - the parties to the document being electronically signed must agree
            to the document being signed electronically.
          </Text>
          <Text>
            Please note also, there may be jurisdictional or global
            incompatibilities. For example, a Deed might be capable of valid
            electronic execution in NSW, but not WA, yet one party to the Deed
            might be located in each State. In this case it is up to the
            document to spell out which laws apply to that document. Similarly,
            a document might be capable of electronic execution in a
            jurisdiction in Australia, but not overseas.
          </Text>
          <Text>
            For any questions and notices, please contact us at: API Software
            Pty Ltd t/a Canyou Software ABN 57 650 764 195 Email:
            support@canyou.com.au
          </Text>
          <small>Last update: 2 Feb 2023 ©</small>
        </VStack>
      </Flex>
    </Layout>
  );
};

export default EsignInformation;
